<template>
  <div class="auth-layout">
    <notifications></notifications>

    <div class="main-content">
      <nuxt></nuxt>
    </div>

    <footer class="py-3 py-lg-5" id="footer-main">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6">
            <div class="copyright text-sm text-center text-lg-left text-white">
              Copyright © {{ new Date().getFullYear() }}
              {{ $config.company.legalName }}
              <span class="d-none d-md-inline-block">
                <span class="px-1">&middot;</span>
                {{ $config.company.address }}
                <span class="px-1">&middot;</span>
                {{ `${$config.company.postal_code} ${$config.company.city}` }}
              </span>
            </div>
          </div>
          <div class="col-xl-6">
            <ul
              class="nav nav-footer justify-content-center justify-content-xl-end"
            >
              <li class="nav-item">
                <a
                  href="https://vendino.com/om-os"
                  class="nav-link text-white"
                  target="_blank"
                  rel="noopener"
                  >{{ $t("about-vendino") }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://vendino.com/blog"
                  class="nav-link text-white"
                  target="_blank"
                  rel="noopener"
                  >{{ $t("blog-and-newsfeed") }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://vendino.com/sikkerhed/privatlivspolitik/"
                  class="nav-link text-white"
                  target="_blank"
                  rel="noopener"
                  >{{ $t("privacy-policy") }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "shop-auth-layout",
  middleware: "guest"
};
</script>

<style lang="scss" scoped>
.auth-layout {
  min-height: 100vh;
  background: url("/img/auth_bg.svg") no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: #2dce89;
  padding-top: 1em;

  .box {
    min-height: 82vh;
  }
}
@media (min-width: 1200px) {
  .auth-layout {
    padding-top: 5em;
  }
}
</style>
