import Vue from "vue";
import VueLazyload from "vue-lazyload";

/* import error from "../assets/error.svg"; */
import loading from "@/static/loader.svg";

Vue.use(VueLazyload, {
  preLoad: 1.4,
  throttleWait: 500,
  /*   error, */
  loading,
  attempt: 1
});
