import axios from "axios";

// state
export const state = () => ({
  job: {}
});

// getters
export const getters = {
  job: state => state.job
};

// mutations
export const mutations = {
  SET_JOB(state, payload) {
    state.job = payload;
  }
};

// actions
export const actions = {
  fetch({ commit }) {
    return axios.get("/shop/events").then(({ data }) => {
      commit("SET_JOB", data.data);
    });
  }
};
