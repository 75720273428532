<template>
  <base-dropdown
    v-if="user"
    class="nav-item font-weight-bold text-sm"
    tag="li"
    title-classes="nav-link"
    title-tag="a"
    icon="ni ni-ungroup"
    :hasToggle="false"
    :title="`${user.supplier.name || ''}`"
    menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
    :persistent="true"
    @change="handleDropdown"
  >
    <template>
      <!-- Dropdown header -->
      <div class="px-3 py-3">
        <h6 class="text-sm text-muted m-0">
          {{
            isLoading
              ? `${$t("please-wait")}..`
              : $t("shop-select-dropdown-description")
          }}
        </h6>
      </div>
      <!-- List group -->
      <collapse-transition :duration="400" group>
        <div
          class="list-group list-group-flush"
          v-for="(supplier, index) in suppliers"
          :key="index"
          @click="handleSwitch(supplier.id)"
        >
          <div
            class="list-group-item list-group-item-action"
            style="cursor: pointer;"
          >
            <div class="row align-items-center">
              <div class="col-auto">
                <!-- Icon -->
                <div class="icon icon-shape bg-white shadow rounded-circle">
                  <i class="ni ni-building text-primary"></i>
                </div>
              </div>
              <div class="col ml--2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm">{{ supplier.name }}</h4>
                    <p class="text-sm mb-0 text-primary">
                      {{ $t(supplier.access_level) }}
                    </p>
                  </div>
                  <div>
                    <badge
                      v-if="supplier.id === user.supplier.id"
                      type="primary"
                      rounded
                      >{{ $t("active") }}</badge
                    >
                    <i
                      v-if="isLoadingId === supplier.id"
                      class="fas fa-spinner fa-spin fa-lg"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </collapse-transition>
    </template>
  </base-dropdown>
</template>

<script>
import axios from "axios";
import { CollapseTransition } from "vue2-transitions";
import { mapGetters } from "vuex";

export default {
  name: "supplier-select-dropdown",
  components: {
    CollapseTransition
  },
  data: function() {
    return {
      isLoading: false,
      isLoadingId: null,
      suppliers: []
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  methods: {
    async getSuppliers() {
      this.isLoading = true;
      const {
        data: { data }
      } = await axios.get("/user/suppliers");
      this.suppliers = data;
      this.isLoading = false;
    },
    async switch(supplierId) {
      this.isLoadingId = supplierId;
      await axios
        .patch(`/user/suppliers/switch/${supplierId}`)
        .then(reponse => {
          // Reloading entire application
          location.reload();
        });
    },
    handleSwitch(supplierId) {
      if (this.user.supplier.id !== supplierId) {
        this.switch(supplierId);
      }
    },
    handleDropdown(open) {
      if (open) {
        this.getSuppliers();
      }
    }
  }
};
</script>

<style></style>
