export default ({ store, redirect }) => {
  if (store.getters["auth/check"]) {
    const user = store.getters["auth/user"];

    if (user.supplier) {
      return redirect("/supplier/dashboard");
    } else {
      return redirect("/shop/dashboard");
    }
  }
};
