import axios from "axios";

// state
export const state = () => ({
  partnerships: [],
  partnershipRequests: [],
  rejectPartnershipRequest: null,
  terminatePartnership: null
});

// getters
export const getters = {
  partnerships: state => state.partnerships,
  partnershipRequests: state => state.partnershipRequests,
  rejectPartnershipRequest: state => state.rejectPartnershipRequest,
  terminatePartnership: state => state.terminatePartnership
};

// mutations
export const mutations = {
  SET_PARTNERSHIPS(state, payload) {
    state.partnerships = payload;
  },
  SET_PARTNERSHIP_REQUESTS(state, payload) {
    state.partnershipRequests = payload;
  },
  SET_REJECT_PARTNERSHIP_REQUEST(state, payload) {
    state.rejectPartnershipRequest = payload;
  },
  SET_TERMINATE_PARTNERSHIP(state, payload) {
    state.terminatePartnership = payload;
  }
};

// actions
export const actions = {
  fetchPartnerships({ commit }) {
    return axios.get("/supplier/partnerships").then(({ data }) => {
      commit("SET_PARTNERSHIPS", data.data);
    });
  },
  fetchPartnershipRequests({ commit }) {
    return axios.get("/supplier/partnership-requests").then(({ data }) => {
      commit("SET_PARTNERSHIP_REQUESTS", data.data);
    });
  }
};
