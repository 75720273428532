<template>
  <modal
    :show.sync="show"
    size="lg"
    headerClasses="border-bottom"
    style="z-index: 99999"
  >
    <!-- Is Loading -->
    <div v-if="isLoading" class="text-center py-4">
      <i class="fas fa-spinner fa-spin mr-1 text-xl"></i>
    </div>

    <!-- Content -->
    <div v-else>
      <h1>{{ $t('upgrade-subscription-notice-title') }}</h1>
      <p class="mb-4">
        {{ $t('upgrade-subscription-notice-description') }}
      </p>
      <quick-start-steps :quickStartSteps="quickStartSteps"></quick-start-steps>

      <div class="d-flex justify-content-center mt-4 mb-2">
        <nuxt-link to="/shop/subscription/upgrade">
          <base-button type="success" class="px-5" @click="show = false" navButton>{{ $t('upgrade-subscription') }}</base-button>
        </nuxt-link>
      </div>
    </div>
  </modal>
</template>

<script>
import QuickStartSteps from "@/components/widgets/QuickStartSteps";

export default {
  name: "UpgradeSubscriptionModal",
  components: {
    QuickStartSteps
  },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    show: {
      get() {
        this.onload();
        return this.$store.state.shop.showUpgradeSubscriptionModal;
      },
      set(value) {
        this.$store.commit("shop/SET_SHOW_UPGRADE_SUBSCRIPTION_MODAL", value);
      }
    },
    quickStartSteps() {
      return [
        {
          title: this.$t("quick-start-guide-steps.step-1-title"),
          text: this.$t("quick-start-guide-steps.step-1-text"),
          link: "/shop/integrations",
          onClick: () => (this.show = false),
          completed: this.$store.state.integrations.all.data.length > 0
        },
        {
          title: this.$t("quick-start-guide-steps.step-2-title"),
          text: this.$t("quick-start-guide-steps.step-2-text"),
          link: "/shop/products/search-products",
          onClick: () => (this.show = false),
          completed:
            this.$store.state.myProducts.products.data.length > 0 ||
            this.$store.state.importProduct.products.data.length > 0
        },
        {
          title: this.$t("quick-start-guide-steps.step-3-title"),
          text: this.$t("quick-start-guide-steps.step-3-text"),
          link: "/shop/products/import-list",
          onClick: () => (this.show = false),
          completed: this.$store.state.myProducts.products.data.length > 0
        },
        {
          title: this.$t('upgrade-subscription'),
          text: `${this.$t('select-a-plan-suited-for-you')}.`,
          link: "/shop/subscription/change",
          onClick: () => (this.show = false),
          completed: false
        }
      ];
    }
  },
  methods: {
    onload() {
      this.isLoading = true;
      Promise.all([
        this.$store.dispatch("integrations/fetchAll"),
        this.$store.dispatch("importProduct/fetchImportProducts"),
        this.$store.dispatch("myProducts/fetchMyProducts")
      ]).then(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      });
    }
  }
};
</script>

<style></style>
