import axios from "axios";

// state
export const state = () => ({
  activities: [],

  options: {
    page: 1
  }
});

// getters
export const getters = {
  activities: state => state.activities,
  options: state => state.options
};

// mutations
export const mutations = {
  SET_ACTIVITIES(state, payload) {
    state.activities = payload;
  },
  SET_PAGE(state, payload) {
    state.options.page = payload;
  }
};

// actions
export const actions = {
  fetchActivities({ commit, state }, options) {
    const params = { ...state.options, ...options };

    return axios.get("/user/activities", { params }).then(({ data }) => {
      commit("SET_ACTIVITIES", data);
    });
  }
};
