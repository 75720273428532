<template>
  <base-dropdown
    v-if="user"
    class="nav-item font-weight-bold text-sm"
    tag="li"
    title-classes="nav-link"
    title-tag="a"
    icon="ni ni-ungroup"
    :hasToggle="false"
    :title="`${user.shop.name || ''}`"
    menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
    :persistent="true"
    @change="handleDropdown"
  >
    <template>
      <!-- Dropdown header -->
      <div class="px-3 py-3">
        <h6 class="text-sm text-muted m-0">
          {{
            isLoading
              ? `${$t("please-wait")}..`
              : $t("shop-select-dropdown-description")
          }}
        </h6>
      </div>
      <!-- List group -->
      <collapse-transition :duration="400">
        <div
          class="overflow-auto border-top border-bottom"
          style="max-height: 350px"
        >
          <div
            class="list-group list-group-flush"
            v-for="(shop, index) in shops"
            :key="index"
            @click="handleSwitch(shop.id)"
          >
            <div
              class="list-group-item list-group-item-action"
              style="cursor: pointer"
            >
              <div class="row align-items-center">
                <div class="col-auto">
                  <!-- Icon -->
                  <div class="icon icon-shape bg-white shadow rounded-circle">
                    <i class="ni ni-box-2 text-info"></i>
                  </div>
                </div>
                <div class="col ml--2">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <h4 class="mb-0 text-sm">{{ shop.name }}</h4>
                      <p class="text-sm mb-0 text-muted">
                        {{ $t(shop.role_name) }}
                      </p>
                    </div>
                    <div>
                      <badge
                        v-if="shop.id === user.shop.id"
                        type="primary"
                        rounded
                        >{{ $t("active") }}</badge
                      >
                      <i
                        v-if="isLoadingId === shop.id"
                        class="fas fa-spinner fa-spin fa-lg"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </collapse-transition>
      <!-- View all -->
      <nuxt-link to="/shop/create">
        <span
          class="dropdown-item text-center text-primary font-weight-bold py-3"
          >{{ $t("create-new") }}</span
        >
      </nuxt-link>
    </template>
  </base-dropdown>
</template>

<script>
import axios from "axios";
import { CollapseTransition } from "vue2-transitions";
import { mapGetters } from "vuex";

export default {
  name: "shop-select-dropdown",
  components: {
    CollapseTransition,
  },
  data: function () {
    return {
      isLoading: false,
      isLoadingId: null,
      shops: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    async getShops() {
      this.isLoading = true;
      const {
        data: { data },
      } = await axios.get("/user/shops");
      this.shops = data;
      this.isLoading = false;
    },
    async switch(shopId) {
      this.isLoadingId = shopId;
      await axios.patch(`/user/shops/switch/${shopId}`).then((reponse) => {
        // Reloading entire application
        location.href = "/shop/dashboard";
      });
    },
    handleSwitch(shopId) {
      if (this.user.shop.id !== shopId) {
        this.switch(shopId);
      }
    },
    handleDropdown(open) {
      if (open) {
        this.getShops();
      }
    },
  },
};
</script>

<style></style>
