import axios from "axios";
import Swal from "sweetalert2";

export default function ({ app, store, redirect, $config: { apiUrl } }) {
  axios.defaults.baseURL = apiUrl;
  axios.defaults.timeout = 20000;

  if (process.server) {
    console.log('Running on server, apiUrl:', apiUrl);
    return;
  }

  // Request interceptor
  axios.interceptors.request.use((config) => {
    config.baseURL = apiUrl;

    // Log request details for debugging
    console.log('Request details:', {
      url: config.url,
      method: config.method,
      baseURL: config.baseURL
    });

    const token = store.getters["auth/token"];
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const locale = store.getters["lang/locale"];
    if (locale) {
      config.headers["Accept-Language"] = locale;
    }

    return config;
  }, error => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  });

  // Response interceptor
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status } = error.response || {};

      switch (status) {
        case 429:
          return handleTooManyRequests();
        case 402:
          return handleSubscriptionRequired();
        case 401:
          if (store.getters["auth/check"]) {
            return handleSessionExpired();
          }
          break;
      }

      return Promise.reject(error);
    }
  );

  const handleTooManyRequests = async () => {
    await Swal.fire({
      icon: "error",
      title: app.i18n.t("too-many-requests"),
      text: app.i18n.t("too-many-requests-description"),
      reverseButtons: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      timer: 61000,
      timerProgressBar: true,
      showCancelButton: false
    });

    redirect("/shop/dashboard");
    return Promise.reject({ response: { data: { errors: {} } } });
  };

  const handleSubscriptionRequired = () => {
    store.commit("shop/SET_SHOW_UPGRADE_SUBSCRIPTION_MODAL", true);
    return Promise.reject({ response: { data: { errors: {} } } });
  };

  const handleSessionExpired = async () => {
    await Swal.fire({
      icon: "warning",
      title: app.i18n.t("session-expired"),
      text: app.i18n.t("session-expired-description"),
      reverseButtons: true,
      confirmButtonText: "Login",
      showCancelButton: false
    });

    store.commit("auth/LOGOUT");
    redirect({ name: "login" });
    return Promise.reject({ response: { data: { errors: {} } } });
  };
}
