<template>
  <fade-transition>
    <div
      v-if="visible"
      class="alert border"
      :class="[
        `alert-${type}`,
        `alert-${size}`,
        { 'alert-dismissible': dismissible },
      ]"
      role="alert"
    >
      <div class="d-flex align-items-center">
        <slot name="icon">
          <span
            class="alert-icon mr-3"
            :class="[`text-${type}`]"
            data-notify="icon"
          >
            <i :class="icon || iconFromType"></i>
          </span>
        </slot>
        <div class="alert-body w-100">
          <slot></slot>
        </div>
        <div v-if="dismissible">
          <slot name="dismiss-icon">
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              @click="dismissAlert"
            >
              <span aria-hidden="true">×</span>
            </button>
          </slot>
        </div>
      </div>
    </div>
  </fade-transition>
</template>
<script>
import { FadeTransition } from "vue2-transitions";

export default {
  name: "base-alert",
  components: {
    FadeTransition,
  },
  props: {
    type: {
      type: String,
      default: "default",
      description: "Alert type",
    },
    size: {
      type: String,
      description: "Modal size",
      validator(value) {
        let acceptedValues = ["", "xs", "sm"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    dismissible: {
      type: Boolean,
      default: false,
      description: "Whether alert is dismissible (closeable)",
    },
    icon: {
      type: String,
      default: "",
      description: "Alert icon to display",
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  computed: {
    iconFromType: function () {
      switch (this.type) {
        case "success":
          return "fas fa-check-circle";
          break;
        case "warning":
          return "fas fa-exclamation-circle";
          break;
        case "danger":
          return "fas fa-exclamation-circle";
          break;
        default:
          return "fas fa-info-circle";
      }
    },
  },
  methods: {
    dismissAlert() {
      this.visible = false;
    },
  },
};
</script>
