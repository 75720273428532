import { cookieFromRequest } from "~/util";

export const actions = {
  nuxtServerInit({ commit }, { req }) {
    const token = cookieFromRequest(req, "token");
    if (token) {
      commit("auth/SET_TOKEN", token);
    }

    const locale = cookieFromRequest(req, "i18n_redirected");
    if (locale) {
      commit("lang/SET_LOCALE", locale);
    }
  }
};
