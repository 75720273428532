import axios from "axios";

// state
export const state = () => ({
  activeFilters: {
    category_id: null,
    supplier_id: 9,
    in_stock: 1,
    has_media: 1,
    is_not_colli: 1,
  },
  options: {
    page: 1,
    search: null,
    order_by: null,
    direction: null,
    include: "product-filters", // Default
  },
  products: [],
  product: {}, // Selected product
  isLoadingProducts: false,
  showRequestPartnershipForSupplier: {},
  productIdsAddedToImportList: []
});

// getters
export const getters = {
  activeFilters: (state) => state.activeFilters,
  products: (state) => state.products,
  options: (state) => state.options,
  product: (state) => state.product,
  isLoadingProducts: (state) => state.isLoadingProducts,
  suppliers: (state) => state.products.included.filters.supplier,
  activeSupplier(state) {
    return state.products.included.filters.supplier.find(
      (el) => el.id === state.activeFilters.supplier_id
    );
  },
  showRequestPartnershipForSupplier: (state) =>
    state.showRequestPartnershipForSupplier,
  productIdsAddedToImportList: (state) => state.productIdsAddedToImportList,
};

// mutations
export const mutations = {
  SET_ACTIVE_FILTER(state, payload) {
    state.activeFilters[payload.filter] = payload.value;
  },
  SET_PRODUCTS(state, payload) {
    state.products = payload;
  },
  SET_PAGE(state, payload) {
    state.options.page = payload;
  },
  SET_SEARCH(state, payload) {
    state.options.search = payload;
  },
  SET_PRODUCT(state, payload) {
    state.product = payload;
  },
  SET_ORDER_BY(state, payload) {
    state.options.order_by = payload.order_by;
    state.options.direction = payload.direction;
  },
  INCREMENT_CART_QUANTITY(state, payload) {
    state.cart.find((e) => e.product.id === payload.id).quantity += 1;
  },
  DECREMENT_CART_QUANTITY(state, payload) {
    state.cart.find((e) => e.product.id === payload.id).quantity -= 1;
  },
  EMPTY_CART(state) {
    state.cart = [];
  },
  SET_PRODUCT_OPTIONS(state, payload) {
    state.cart.find(
      (e) => e.product.id === payload.product_id
    ).product_options = payload.product_options;
  },
  SET_IS_LOADING_PRODUCTS(state, payload) {
    state.isLoadingProducts = payload;
  },
  SET_SHOW_REQUEST_PARTNERSHIP_FOR_SUPPLIER(state, payload) {
    state.showRequestPartnershipForSupplier = payload;
  },
  SET_PRODUCT_IDS_ADDED_TO_IMPORT_LIST(state, payload) {
    state.productIdsAddedToImportList = payload;
  },
  APPEND_TO_PRODUCT_IDS_ADDED_TO_IMPORT_LIST(state, payload) {
    state.productIdsAddedToImportList.push(payload);
  },
  REMOVE_FROM_PRODUCT_IDS_ADDED_TO_IMPORT_LIST(state, payload) {
    const indexOfId = state.productIdsAddedToImportList.indexOf(payload);
    state.productIdsAddedToImportList.splice(indexOfId, 1);
  }
};

// actions
export const actions = {
  fetchProducts({ commit, state }, options) {
    commit("SET_IS_LOADING_PRODUCTS", true);

    const params = { ...state.activeFilters, ...state.options, ...options };

    return axios.get("/products", { params }).then(({ data }) => {
      commit("SET_PRODUCTS", data);
      commit("SET_PRODUCT_IDS_ADDED_TO_IMPORT_LIST", [
        ...data.data.filter((el) => el.is_in_import_list).map((el) => el.id),
      ]);
      commit("SET_IS_LOADING_PRODUCTS", false);
    });
  },
  fetchProduct({ commit }, id) {
    return axios.get("/products/" + id).then(({ data }) => {
      commit("SET_PRODUCT", data.data);
    });
  },
};
