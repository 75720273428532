<template>
  <card bodyClasses="py-1 px-3" class="mb-0 d-none d-md-block border">
    <nuxt-link to="/shop/profile">
      <div class="d-flex">
        <div class="d-flex align-items-center">
          <pulse-circle type="danger" size="sm" class="mr-3"></pulse-circle>
        </div>
        <div>
          <p class="mb-0 text-xs font-weight-bold text-danger">
            {{ $t("company-vat-missing") }}
          </p>
          <p class="mb-0 text-xs text-default">
            {{ $t("company-vat-missing-description") }}
          </p>
        </div>
      </div>
    </nuxt-link>
  </card>
</template>

<script>
import PulseCircle from "@/components/widgets/PulseCircle";

export default {
  name: "missing-vat-notice",
  components: {
    PulseCircle
  }
};
</script>

<style></style>
