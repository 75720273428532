<template>
  <div>
    <div
      class="px-3 pt-3 pb-2 upgrade-notice w-100 text-center border-top"
      v-if="activeSubscription"
    >
      <p class="text-sm font-weight-bold text-dark mb-0">
        {{ activeSubscription.name }}
      </p>
      <p class="text-xs mt--1">{{ $t("company") }} ID: sho{{ user.shop.id }}</p>
    </div>
    <div class="px-3 pt-2 pb-3 upgrade-notice w-100 text-center" v-else>
      <nuxt-link to="/shop/subscription/upgrade">
        <base-button type="success" size="sm" class="mx-auto mb-2 px-4">{{
          $t("upgrade-now")
        }}</base-button>
      </nuxt-link>
      <p class="text-xs">{{ $t("free-trial-account") }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "upgradeNotice",
  computed: {
    ...mapGetters({
      activeSubscription: "shop/activeSubscription",
      user: "auth/user"
    })
  },
  created() {
    this.$store.dispatch("shop/fetchSubscriptions");
  }
};
</script>

<style lang="scss">
.upgrade-notice {
  position: absolute;
  bottom: 0;
}
</style>
