export default ({ store, redirect, route, query }) => {
  if (!store.getters["auth/check"]) {
    // If user is coming from connect
    if (route.path.includes("/connect")) {
      return redirect("/connect/login", {
        sessionToken: query.sessionToken
      });
    }

    return redirect("/auth/login", {
      redirectTo: route.fullPath
    });
  }
};
