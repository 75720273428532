import axios from "axios";

// state
export const state = () => ({
  orders: [],
  order: [],

  showConfirmModalForOrder: "",

  options: {
    page: 1
  }
});

// getters
export const getters = {
  orders: state => state.orders,
  order: state => state.order.data,
  showConfirmModalForOrder: state => state.showConfirmModalFor
};

// mutations
export const mutations = {
  SET_ORDERS(state, payload) {
    state.orders = payload;
  },
  SET_ORDER(state, payload) {
    state.order = payload;
  },
  SET_PAGE(state, payload) {
    state.options.page = payload;
  },
  SHOW_CONFIRM_MODAL_FOR_ORDER(state, payload) {
    state.showConfirmModalForOrder = payload;
  }
};

// actions
export const actions = {
  fetchAll({ commit, state }, options) {
    const params = { ...state.options, ...options };

    return axios.get("/shop/orders", { params }).then(({ data }) => {
      commit("SET_ORDERS", data);
    });
  },
  fetch({ commit }, id) {
    return axios.get(`/shop/orders/${id}`).then(({ data }) => {
      commit("SET_ORDER", data);
    });
  },
  confirmOrder({}, id) {
    return axios.post(`/shop/orders/${id}/confirm`);
  }
};
