<template>
  <div class="container-fluid bg-success" style="height: 100vh;">
    <div class="row h-100 d-flex justify-content-center align-items-center">
      <div class="col-12 col-md-8 col-lg-6 text-md-center px-5">
        <div
          v-if="dontShowError.includes(error.statusCode)"
          style="height: 750px;"
        ></div>
        <!-- Page not found -->
        <div v-else-if="error.statusCode === 404" class="text-center mt--8">
          <i class="ni ni-map-big text-white error-icon mb-3"></i>
          <h1 class="display-2 text-white">
            {{ $t("page-not-found") }}
          </h1>
          <p class="text-lead text-white mb-0">
            {{ $t("page-not-found-description") }}
          </p>

          <p class="text-lead text-white">
            {{ $t("if-this-error-persists-notice") }}
          </p>
          <div class="mt-6">
            <nuxt-link to="/">
              <base-button type="primary" class="px-5">
                {{ $t("take-me-back") }}
              </base-button></nuxt-link
            >
          </div>
        </div>

        <!-- Server down -->
        <div
          v-else-if="(error.statusCode === 521) | (error.statusCode === 502)"
          class="text-center mt--8"
        >
          <i
            class="fa fa-exclamation-circle text-white error-icon mb-3"
            aria-hidden="true"
          ></i>
          <h1 class="text-white">
            {{ $t("error-server-down-greeting") }}
          </h1>
          <p class="text-lead text-white mb-0">
            {{ $t("error-server-down-description") }}
          </p>

          <p class="text-lead text-white">
            {{ $t("please-try-again-later-description") }}
          </p>
          <p class="text-white" style="opacity: 0.7;">
            {{ $t("last-checked") }}: {{ new Date().toLocaleString() }}
          </p>
        </div>

        <!-- Server error -->
        <div v-else class="text-center mt--8">
          <i
            class="fa fa-exclamation-circle text-white error-icon mb-3"
            aria-hidden="true"
          ></i>
          <h1 class="display-2 text-white">Ooops!</h1>
          <p class="text-lead text-white mb-0">{{ $t("error-greeting") }}</p>

          <p class="text-lead text-white">
            {{ $t("if-this-error-persists-notice") }}
          </p>
          <div class="mt-6">
            <nuxt-link to="/">
              <base-button type="primary" class="px-5">
                {{ $t("take-me-back") }}
              </base-button></nuxt-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "error-layout",
  props: ["error"],
  data() {
    return {
      dontShowError: [429, 401]
    };
  }
};
</script>

<style lang="scss" scoped>
.error-icon {
  font-size: 90pt;
  opacity: 0.45;
}
</style>
