import axios from "axios";

// state
export const state = () => ({
  products: [],

  options: {
    page: 1,
    search_query: "",
  },

  showImportNowModal: false,
  importOptions: {
    limit: null,
  },
});

// getters
export const getters = {
  products: (state) => state.products,
  options: (state) => state.options,
  importProgress: (state) => state.importProgress,
  showImportNowModal: (state) => state.showImportNowModal,
  importOptions: (state) => state.importOptions,
};

// mutations
export const mutations = {
  SET_IMPORT_PRODUCTS(state, payload) {
    state.products = payload;
  },
  SET_PAGE(state, payload) {
    state.options.page = payload;
  },
  SET_SEARCH_QUERY(state, payload) {
    state.options.search_query = payload;
  },
  RESET_OPTIONS(state) {
    state.options = {
      page: 1,
      search_query: "",
    };
  },
  SET_SHOW_IMPORT_NOW_MODAL(state, payload) {
    state.showImportNowModal = payload;
  },
  SET_IMPORT_LIMIT(state, payload) {
    state.importOptions.limit = payload;
  },
};

// actions
export const actions = {
  fetchImportProducts({ commit, state }, options) {
    const params = { ...state.options, ...options };

    return axios.get("/import-products", { params }).then(({ data }) => {
      commit("SET_IMPORT_PRODUCTS", data);
    });
  },
  addImportProduct({}, product) {
    return axios.post("/import-products", {
      product_id: product.id,
    });
  },
  destroyImportProduct({}, product) {
    return axios.delete("/import-products/" + product.id);
  },
  destroyAllImportProducts() {
    return axios.delete("/import-products");
  },
  updateImportProduct({ dispatch }, product) {
    return axios.patch("/import-products/" + product.id, product).then(() => {
      dispatch("fetchImportProducts");
    });
  },
  bulkImport({ state }, options) {
    const params = { ...state.importOptions, ...options };

    return axios.post("/import-products/import/bulk", params);
  },
};
