import axios from "axios";

// state
export const state = () => ({
  all: {},
  selected: {}, // Selected integration
  selectedOptions: {}, // Selected integration options

  showEditModal: false,
  showDeleteModal: false,
  showCreateModalForPlatform: "",
  showOptionsModal: false,
});

// getters
export const getters = {
  all: (state) => state.all,
  selected: (state) => state.selected,
  hasSelected: (state) => Object.keys(state.selected).length > 0,
  selectedOptions: (state) => state.selectedOptions,
  showEditModal: (state) => state.showEditModal,
  showDeleteModal: (state) => state.showDeleteModal,
  showCreateModalForPlatform: (state) => state.showCreateModalForPlatform,
  showOptionsModal: (state) => state.showOptionsModal,
};

// mutations
export const mutations = {
  SET_ALL(state, payload) {
    state.all = payload;
  },
  SET_SELECTED(state, payload) {
    state.selected = payload;
  },
  SET_SELECTED_OPTIONS(state, payload) {
    state.selectedOptions = payload;
  },
  SET_SHOW_EDIT_MODAL(state, payload) {
    state.showEditModal = payload;
  },
  SET_SHOW_DELETE_MODAL(state, payload) {
    state.showDeleteModal = payload;
  },
  SET_SHOW_CREATE_MODAL_FOR_PLATFORM(state, payload) {
    state.showCreateModalForPlatform = payload;
  },
  SET_SHOW_OPTIONS_MODAL(state, payload) {
    state.showOptionsModal = payload;
  },
};

// actions
export const actions = {
  fetchAll({ commit }) {
    return axios.get("/shop/integrations").then(({ data }) => {
      commit("SET_ALL", data);
    });
  },
  fetch({ commit }, id) {
    return axios.get(`/shop/integrations/${id}`).then(({ data }) => {
      commit("SET_SELECTED", data.data);
    });
  },
  fetchOptions({ commit }, id) {
    return axios.get(`/shop/integrations/${id}/options`).then(({ data }) => {
      commit("SET_SELECTED_OPTIONS", data.data.options);
    });
  },
  patchOptions({ commit, state }, params) {
    return axios
      .patch(`/shop/integrations/${state.selected.id}/options`, params)
      .then(({ data }) => {
        commit("SET_SELECTED", data.data);
      });
  },
  patchDataResources({ commit, state }, params) {
    return axios
      .patch(`/shop/integrations/${state.selected.id}/data-resources`, params)
      .then(({ data }) => {
        commit("SET_SELECTED", data.data);
      });
  },
};
