<template>
  <modal :show="true" v-if="show" bodyClasses="py-4">
    <p class="text-center">{{ $t("confirm-logout-greeting") }}</p>
    <!-- Buttons -->
    <div class="d-flex justify-content-between mt-4">
      <base-button type="success" outline @click="handleCloseModal()">{{
        $t("keep-me-logged-in")
      }}</base-button>
      <base-button type="danger" outline @click="logout()"
        ><i class="ni ni-user-run mr-1"></i> {{ $t("logout") }}</base-button
      >
    </div>
  </modal>
</template>

<script>
export default {
  name: "confirm-logout-modal",
  props: {
    show: {
      type: Boolean,
      description: "Whether to show logout modal"
    },
    redirectPath: {
      type: String,
      default: "/auth/login",
      description: "Path to redirect user once logged out"
    }
  },
  methods: {
    handleCloseModal() {
      this.$emit("update:show", false);
    },
    async logout() {
      // Log out the user.
      await this.$store.dispatch("auth/logout");

      // Redirect to login.
      this.$router.push({
        path: this.redirectPath,
        query: { loggedOut: "true" }
      });
    }
  }
};
</script>

<style></style>
