import axios from "axios";

// state
export const state = () => ({
  all: [],
});

// getters
export const getters = {
  all: (state) => state.all,
};

// mutations
export const mutations = {
  SET_ALL(state, payload) {
    state.all = payload;
  },
};

// actions
export const actions = {
  fetchAll({ commit }) {
    return axios.get("/shop/feeds").then(({ data }) => {
      commit("SET_ALL", data.data);
    });
  },
  create({}, payload) {
    return axios.post("/shop/feeds", payload);
  },
};
