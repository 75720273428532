<template>
  <div
    class="header pb-6 d-flex align-items-center"
    :class="{ [`bg-${type}`]: type }"
  >
    <!-- Mask -->
    <span class="mask bg-success opacity-8"></span>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "base-header",
  props: {
    type: {
      type: String,
      default: "success",
      description: "Header background type"
    }
  }
};
</script>
<style lang="scss">
.header {
  min-height: 220px;
}
h1 {
  font-size: 1.45em;
}
</style>
