<template>
    <div>

      <!-- Completed banner -->
      <div
        class="progress-tracker-banner text-white py-4"
        v-if="completedJob.name"
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-10 col-lg-6">
              <div class="d-flex align-items-center">
                <div class="mr-3">
                  <div><i class="fa fa-check"></i></div>
                </div>
                <p class="font-weight-bold text-truncate mb-0">
                  {{ $t(`running-jobs.${completedJob.name}-on-complete-title`) }}
                </p>
              </div>
            </div>
            <div class="col-auto ml-auto">
              <button type="button" class="close pt-1" @click="completedJob = {}">
                <span>×</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- In progress banner -->
      <div
        class="progress-tracker-banner text-white py-4"
        v-if="runningJob.name"
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-10 col-lg-6">
              <div class="d-flex align-items-center">
                <div class="mr-3">
                  <div class="spinner"></div>
                </div>
                <p
                  class="font-weight-bold text-truncate mb-0"
                >
                  {{ $t(`running-jobs.${runningJob.name}-title`) }}...
                </p>
              </div>
            </div>
            <div v-if="runningJob.progress > 0" class="col-1 text-right pr-0">
              <p class="text-sm font-weight-bold mb-0">
                {{ runningJob.progress }}%
              </p>
            </div>
            <slide-x-left-transition>
              <div v-if="runningJob.progress > 0" class="d-none d-md-block col-3">
                <base-progress
                  type="info"
                  :height="8"
                  :value="runningJob.progress"
                  progressClasses="mb-0"
                ></base-progress>
              </div>
            </slide-x-left-transition>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { SlideXLeftTransition } from "vue2-transitions";
export default {
  name: "progress-tracker-banner",
  components: {
    SlideXLeftTransition
  },
  computed: {
    runningJob() {
      return this.$store.state.runningJob.job;
    }
  },
  data() {
    return {
      completedJob: {}
    };
  },
  watch: {
    runningJob(newJob, oldJob) {
      if (newJob.progress < 100) {
        setTimeout(() => {
          this.$store.dispatch("runningJob/fetch");
        }, 5000);
      } else if ("progress" in oldJob) {
        // Running job completed
        this.handleShowCompletedJob(oldJob);
        // Notify
        this.$notify({
          title: this.$t(`running-jobs.${oldJob.name}-on-complete-title`),
          message: this.$t(
            `running-jobs.${oldJob.name}-on-complete-description`
          ),
          type: "success"
        });
      }
    }
  },
  methods: {
    handleShowCompletedJob(completedJob) {
      this.completedJob = completedJob;

      // Hide after 30 seconds
      setTimeout(() => (this.completedJob = {}), 30 * 1000);
    }
  },
  created() {
    this.$store.dispatch("runningJob/fetch");
  }
};
</script>

<style lang="scss">
.progress-tracker-banner {
  color: black;
  background-color: #65dba8;
  border-bottom: 1px solid #61d3a2;
}
</style>
