<template>
  <collapse-transition :duration="100">
    <div
      class="offline-notice-banner bg-yellow text-dark py-3"
      v-if="$nuxt.isOffline"
    >
      <div class="container-fluid">
        <div class="d-flex align-items-center">
          <div class="spinner mr-3"></div>
          <p class="font-weight-bold mb-0">{{ $t("trying-to-connect") }}...</p>
        </div>
      </div>
    </div>
  </collapse-transition>
</template>

<script>
import { CollapseTransition } from "vue2-transitions";
export default {
  name: "OfflineNoticeBanner",
  components: {
    CollapseTransition
  }
};
</script>

<style lang="scss">
.offline-notice-banner {
  .spinner {
    width: 22px;
    height: 22px;
    border: 0.16rem solid rgba(255, 255, 255, 0);
    border-radius: 50%;
    border-top-color: black;
    border-right-color: black;
    animation: spin 1s linear infinite;
    -webkit-animation: spin 1s linear infinite;

    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
  }
}
</style>
