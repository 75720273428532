<template>
  <div :class="`pulse-circle ${size} ${type}`"></div>
</template>

<script>
export default {
  name: "pulse-circle",
  props: {
    size: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss">
.pulse-circle {
  position: relative;
  width: 20px;
  height: 20px;

  &.lg {
    width: 30px;
    height: 30px;
  }

  &.sm {
    width: 15px;
    height: 15px;
  }

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: var(--default);
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &.danger:before {
    background-color: var(--danger);
  }

  &.info:before {
    background-color: var(--info);
  }

  &.success:before {
    background-color: var(--success);
  }

  &.warning:before {
    background-color: var(--warning);
  }

  &.primary:before {
    background-color: var(--primary);
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
</style>
