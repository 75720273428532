export default ({ store, route, redirect }) => {
  const user = store.getters["auth/user"];

  if (!user.shop) {
    // Avoiding infinite loop
    if (route.path !== "/shop/signup/setup-shop-profile") {
      return redirect({
        path: "/shop/signup/setup-shop-profile",
        query: {
          redirectTo: route.fullPath
        }
      });
    }
  }
};
