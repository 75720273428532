import axios from "axios";

// state
export const state = () => ({
  products: [],

  options: {
    page: 1,
    status: "active",
    search_query: ""
  }
});

// getters
export const getters = {
  products: state => state.products,
  options: state => state.options
};

// mutations
export const mutations = {
  SET_PRODUCTS(state, payload) {
    state.products = payload;
  },
  SET_PAGE(state, payload) {
    state.options.page = payload;
  },
  SET_STATUS(state, payload) {
    state.options.status = payload;
  },
  SET_SEARCH_QUERY(state, payload) {
    state.options.search_query = payload;
  },
  RESET_OPTIONS(state) {
    state.options = {
      page: 1,
      status: "active",
      search_query: ""
    };
  }
};

// actions
export const actions = {
  fetchMyProducts({ commit, state }, options) {
    const params = { ...state.options, ...options };

    return axios.get("/shop/products", { params }).then(({ data }) => {
      commit("SET_PRODUCTS", data);
    });
  }
};
