<template>
  <footer class="container-fluid footer px-4 mx-auto">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          Copyright © {{ year }} {{ $config.company.legalName }}
          <span class="px-1">&middot;</span>
          {{ $config.company.address }}
          <span class="px-1">&middot;</span>
          {{ `${$config.company.postal_code} ${$config.company.city}` }}
        </div>
      </div>
      <div class="col-lg-6">
        <ul
          class="nav nav-footer justify-content-center justify-content-lg-end"
        >
          <li class="nav-item">
            <a
              href="https://vendino.com/om-os"
              class="nav-link"
              target="_blank"
              rel="noopener"
              >{{ $t("about-vendino") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://vendino.com/blog"
              class="nav-link"
              target="_blank"
              rel="noopener"
              >{{ $t("blog-and-newsfeed") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://vendino.com/sikkerhed/brugervilkaar/"
              class="nav-link"
              target="_blank"
              rel="noopener"
              >{{ $t("terms-of-service") }}</a
            >
          </li>
        </ul>
      </div>
      <div class="col-lg-12 mt-1">
        <p class="copyright text-center text-lg-left text-muted mb-0">
          {{ $t("third-party-disclaimer-notice") }}
        </p>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
