<template>
  <base-nav
    container-classes="container-fluid"
    :class="`navbar-top navbar-expand navbar-dark bg-${color}`"
  >
    <!-- Contact card -->
    <card bodyClasses="py-1 px-3" class="mb-0 d-none d-md-block">
      <p class="mb-0 text-xs font-weight-bold">
        {{ $t("lets-keep-in-touch") }}
      </p>
      <p class="mb-0 text-xs">
        {{ $t("e-mail-us") }}:
        <a href="mailto:hello@vendino.com">hello@vendino.com</a>
        <!--  | {{ $t("call-us") }}: <a href="tel:+4571969596">+45 71 96 95 96</a> -->
      </p>
    </card>
    <slot name="notices"></slot>

    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler sidenav-toggler-dark"
          :class="{
            active: $sidebar.showSidebar,
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>

      <slot></slot>
    </ul>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <a
          v-if="user"
          href="#"
          class="nav-link pr-0"
          @click.prevent
          slot="title-container"
        >
          <div class="media align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" :src="user.photo_url" />
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm">{{ user.email }}</span>
            </div>
          </div>
        </a>

        <template>
          <nuxt-link
            v-for="(item, index) in dropdownMenuItems"
            :to="item.path"
            class="dropdown-item"
            :key="index"
          >
            <i :class="item.icon"></i>
            <span>{{ $t(item.key) }}</span>
          </nuxt-link>
          <slot name="dropdownMenu"></slot>
        </template>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { mapGetters } from "vuex";
import { CollapseTransition } from "vue2-transitions";
import BaseNav from "@/components/argon-core/Navbar/BaseNav.vue";

export default {
  components: {
    CollapseTransition,
    BaseNav,
  },
  props: {
    color: {
      type: String,
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
    dropdownMenuItems: {
      type: Array,
      description: "Array of items in the dropdown menu",
    },
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    ...mapGetters({
      user: "auth/user",
    }),
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
  },
};
</script>
