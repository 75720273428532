import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e8062d1a = () => interopDefault(import('../pages/connect/index.vue' /* webpackChunkName: "pages/connect/index" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _0d7d98a9 = () => interopDefault(import('../pages/auth/reset-password/index.vue' /* webpackChunkName: "pages/auth/reset-password/index" */))
const _9b880836 = () => interopDefault(import('../pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _6079f0ae = () => interopDefault(import('../pages/connect/error.vue' /* webpackChunkName: "pages/connect/error" */))
const _55a48c8a = () => interopDefault(import('../pages/connect/login.vue' /* webpackChunkName: "pages/connect/login" */))
const _1a83c5ea = () => interopDefault(import('../pages/connect/shopify/index.vue' /* webpackChunkName: "pages/connect/shopify/index" */))
const _a9721c72 = () => interopDefault(import('../pages/connect/signup.vue' /* webpackChunkName: "pages/connect/signup" */))
const _df1c408a = () => interopDefault(import('../pages/shop/create.vue' /* webpackChunkName: "pages/shop/create" */))
const _d44578b6 = () => interopDefault(import('../pages/shop/dashboard.vue' /* webpackChunkName: "pages/shop/dashboard" */))
const _77d7f921 = () => interopDefault(import('../pages/shop/integrations/index.vue' /* webpackChunkName: "pages/shop/integrations/index" */))
const _1b061d8b = () => interopDefault(import('../pages/shop/page-disabled.vue' /* webpackChunkName: "pages/shop/page-disabled" */))
const _af25cb0c = () => interopDefault(import('../pages/shop/profile.vue' /* webpackChunkName: "pages/shop/profile" */))
const _06ac4b7d = () => interopDefault(import('../pages/shop/server-error.vue' /* webpackChunkName: "pages/shop/server-error" */))
const _528cb0c2 = () => interopDefault(import('../pages/shop/subscription/index.vue' /* webpackChunkName: "pages/shop/subscription/index" */))
const _2a4d7afb = () => interopDefault(import('../pages/supplier/dashboard.vue' /* webpackChunkName: "pages/supplier/dashboard" */))
const _67763a50 = () => interopDefault(import('../pages/supplier/partnerships.vue' /* webpackChunkName: "pages/supplier/partnerships" */))
const _55319e6f = () => interopDefault(import('../pages/supplier/user-no-relation.vue' /* webpackChunkName: "pages/supplier/user-no-relation" */))
const _08d45926 = () => interopDefault(import('../pages/connect/shopify/authenticate.vue' /* webpackChunkName: "pages/connect/shopify/authenticate" */))
const _563bdfae = () => interopDefault(import('../pages/shop/orders/history.vue' /* webpackChunkName: "pages/shop/orders/history" */))
const _4d211322 = () => interopDefault(import('../pages/shop/orders/redirect-to-order.vue' /* webpackChunkName: "pages/shop/orders/redirect-to-order" */))
const _0f32c2a8 = () => interopDefault(import('../pages/shop/products/feeds/index.vue' /* webpackChunkName: "pages/shop/products/feeds/index" */))
const _7c6f9c9a = () => interopDefault(import('../pages/shop/products/import-list.vue' /* webpackChunkName: "pages/shop/products/import-list" */))
const _d3cb808e = () => interopDefault(import('../pages/shop/products/my-products.vue' /* webpackChunkName: "pages/shop/products/my-products" */))
const _673dc33d = () => interopDefault(import('../pages/shop/products/search-products.vue' /* webpackChunkName: "pages/shop/products/search-products" */))
const _87ba0ebe = () => interopDefault(import('../pages/shop/signup/complete.vue' /* webpackChunkName: "pages/shop/signup/complete" */))
const _6c76d60a = () => interopDefault(import('../pages/shop/signup/setup-shop-profile.vue' /* webpackChunkName: "pages/shop/signup/setup-shop-profile" */))
const _57808fb3 = () => interopDefault(import('../pages/shop/subscription/change.vue' /* webpackChunkName: "pages/shop/subscription/change" */))
const _c87ffbee = () => interopDefault(import('../pages/shop/subscription/thank-you.vue' /* webpackChunkName: "pages/shop/subscription/thank-you" */))
const _e0462aae = () => interopDefault(import('../pages/shop/subscription/upgrade.vue' /* webpackChunkName: "pages/shop/subscription/upgrade" */))
const _2d829a7a = () => interopDefault(import('../pages/shop/user/privacy.vue' /* webpackChunkName: "pages/shop/user/privacy" */))
const _cb8bbf38 = () => interopDefault(import('../pages/shop/user/profile.vue' /* webpackChunkName: "pages/shop/user/profile" */))
const _30a127eb = () => interopDefault(import('../pages/supplier/auth/login.vue' /* webpackChunkName: "pages/supplier/auth/login" */))
const _501398a6 = () => interopDefault(import('../pages/supplier/user/privacy.vue' /* webpackChunkName: "pages/supplier/user/privacy" */))
const _ee1cbd64 = () => interopDefault(import('../pages/supplier/user/profile.vue' /* webpackChunkName: "pages/supplier/user/profile" */))
const _8393d37a = () => interopDefault(import('../pages/shop/integrations/advanced-settings/mapping-values.vue' /* webpackChunkName: "pages/shop/integrations/advanced-settings/mapping-values" */))
const _21cae74c = () => interopDefault(import('../pages/shop/integrations/advanced-settings/mutating-values.vue' /* webpackChunkName: "pages/shop/integrations/advanced-settings/mutating-values" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7a3646c2 = () => interopDefault(import('../pages/shop/orders/show/_orderNumber.vue' /* webpackChunkName: "pages/shop/orders/show/_orderNumber" */))
const _27fd1433 = () => interopDefault(import('../pages/auth/reset-password/_token.vue' /* webpackChunkName: "pages/auth/reset-password/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/connect",
    component: _e8062d1a,
    name: "connect"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login"
  }, {
    path: "/auth/reset-password",
    component: _0d7d98a9,
    name: "auth-reset-password"
  }, {
    path: "/auth/signup",
    component: _9b880836,
    name: "auth-signup"
  }, {
    path: "/connect/error",
    component: _6079f0ae,
    name: "connect-error"
  }, {
    path: "/connect/login",
    component: _55a48c8a,
    name: "connect-login"
  }, {
    path: "/connect/shopify",
    component: _1a83c5ea,
    name: "connect-shopify"
  }, {
    path: "/connect/signup",
    component: _a9721c72,
    name: "connect-signup"
  }, {
    path: "/shop/create",
    component: _df1c408a,
    name: "shop-create"
  }, {
    path: "/shop/dashboard",
    component: _d44578b6,
    name: "shop-dashboard"
  }, {
    path: "/shop/integrations",
    component: _77d7f921,
    name: "shop-integrations"
  }, {
    path: "/shop/page-disabled",
    component: _1b061d8b,
    name: "shop-page-disabled"
  }, {
    path: "/shop/profile",
    component: _af25cb0c,
    name: "shop-profile"
  }, {
    path: "/shop/server-error",
    component: _06ac4b7d,
    name: "shop-server-error"
  }, {
    path: "/shop/subscription",
    component: _528cb0c2,
    name: "shop-subscription"
  }, {
    path: "/supplier/dashboard",
    component: _2a4d7afb,
    name: "supplier-dashboard"
  }, {
    path: "/supplier/partnerships",
    component: _67763a50,
    name: "supplier-partnerships"
  }, {
    path: "/supplier/user-no-relation",
    component: _55319e6f,
    name: "supplier-user-no-relation"
  }, {
    path: "/connect/shopify/authenticate",
    component: _08d45926,
    name: "connect-shopify-authenticate"
  }, {
    path: "/shop/orders/history",
    component: _563bdfae,
    name: "shop-orders-history"
  }, {
    path: "/shop/orders/redirect-to-order",
    component: _4d211322,
    name: "shop-orders-redirect-to-order"
  }, {
    path: "/shop/products/feeds",
    component: _0f32c2a8,
    name: "shop-products-feeds"
  }, {
    path: "/shop/products/import-list",
    component: _7c6f9c9a,
    name: "shop-products-import-list"
  }, {
    path: "/shop/products/my-products",
    component: _d3cb808e,
    name: "shop-products-my-products"
  }, {
    path: "/shop/products/search-products",
    component: _673dc33d,
    name: "shop-products-search-products"
  }, {
    path: "/shop/signup/complete",
    component: _87ba0ebe,
    name: "shop-signup-complete"
  }, {
    path: "/shop/signup/setup-shop-profile",
    component: _6c76d60a,
    name: "shop-signup-setup-shop-profile"
  }, {
    path: "/shop/subscription/change",
    component: _57808fb3,
    name: "shop-subscription-change"
  }, {
    path: "/shop/subscription/thank-you",
    component: _c87ffbee,
    name: "shop-subscription-thank-you"
  }, {
    path: "/shop/subscription/upgrade",
    component: _e0462aae,
    name: "shop-subscription-upgrade"
  }, {
    path: "/shop/user/privacy",
    component: _2d829a7a,
    name: "shop-user-privacy"
  }, {
    path: "/shop/user/profile",
    component: _cb8bbf38,
    name: "shop-user-profile"
  }, {
    path: "/supplier/auth/login",
    component: _30a127eb,
    name: "supplier-auth-login"
  }, {
    path: "/supplier/user/privacy",
    component: _501398a6,
    name: "supplier-user-privacy"
  }, {
    path: "/supplier/user/profile",
    component: _ee1cbd64,
    name: "supplier-user-profile"
  }, {
    path: "/shop/integrations/advanced-settings/mapping-values",
    component: _8393d37a,
    name: "shop-integrations-advanced-settings-mapping-values"
  }, {
    path: "/shop/integrations/advanced-settings/mutating-values",
    component: _21cae74c,
    name: "shop-integrations-advanced-settings-mutating-values"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/shop/orders/show/:orderNumber?",
    component: _7a3646c2,
    name: "shop-orders-show-orderNumber"
  }, {
    path: "/auth/reset-password/:token?",
    component: _27fd1433,
    name: "auth-reset-password-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
