<template>
  <div class="wrapper">
    <notifications></notifications>
    <!-- Sidebar menu -->
    <side-bar logoLink="/shop/dashboard">
      <template slot="links">
        <sidebar-item
          :link="{
            name: $t('search-products'),
            icon: 'fas fa-tasks text-info',
            path: '/shop/products/search-products',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: $t('manage-products'),
            icon: 'ni ni-bag-17 text-orange',
          }"
        >
          <sidebar-item
            :link="{
              name: $t('my-import-list'),
              path: '/shop/products/import-list',
            }"
          />
          <sidebar-item
            :link="{
              name: $t('my-products'),
              path: '/shop/products/my-products',
            }"
          />
          <sidebar-item
            :link="{
              name: $t('my-feeds'),
              path: '/shop/products/feeds',
            }"
          />
        </sidebar-item>

        <!--
        <sidebar-item
          :link="{
            name: 'Dropshipping',
            icon: 'ni ni-money-coins text-success',
          }"
        >
          <sidebar-item
            :link="{ name: $t('order-history'), path: '/shop/orders/history' }"
          />
        </sidebar-item>
        -->
      </template>

      <!-- Sidebar - Links after -->
      <template #links-after>
        <hr class="my-3" />
        <h6 class="navbar-heading p-0 text-muted">
          {{ $t("getting-started") }}
        </h6>

        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <nuxt-link to="/shop/integrations" class="nav-link"
              ><i class="ni ni-spaceship"></i>
              <span class="nav-link-text">{{
                $t("integrations")
              }}</span></nuxt-link
            >
          </li>
          <li class="nav-item" @click="handleShowQuickStartGuide()">
            <div class="nav-link" style="cursor: pointer">
              <i class="ni ni-support-16"></i>
              <span class="nav-link-text">{{ $t("quick-start-guide") }}</span>
            </div>
          </li>
        </ul>
      </template>

      <!-- Sidebar - Bottom -->
      <template #bottom>
        <upgrade-notice
          v-if="$sidebar.isMinimized === false || $sidebar.hovered === true"
        ></upgrade-notice>
      </template>
    </side-bar>

    <!-- Navbar -->
    <div class="main-content">
      <dashboard-navbar
        color="success"
        :dropdownMenuItems="dashboardNavbar.dropdownMenuItems"
      >
        <template #notices>
          <!-- Missing VAT notice -->
          <slide-y-up-transition group>
            <missing-vat-notice
              class="ml-2"
              v-if="!user.shop.vat"
              :key="0"
            ></missing-vat-notice>
          </slide-y-up-transition>
        </template>
        <shop-select-dropdown></shop-select-dropdown>
        <template slot="dropdownMenu">
          <div class="dropdown-divider"></div>
          <button
            type="button"
            @click="showConfirmLogoutModal = true"
            class="dropdown-item"
          >
            <i class="ni ni-user-run"></i>
            <span>{{ $t("logout") }}</span>
          </button>
        </template>
      </dashboard-navbar>

      <!-- Confirm Logout Modal -->
      <confirm-logout-modal
        :show.sync="showConfirmLogoutModal"
      ></confirm-logout-modal>

      <!-- Shop Quick Start Guide Modal -->
      <shop-quick-start-modal></shop-quick-start-modal>

      <!-- Subscription limit reached modal -->
      <upgrade-subscription-modal></upgrade-subscription-modal>

      <!-- Progress Tracker -->
      <progress-tracker-banner></progress-tracker-banner>

      <!-- Offline Notice -->
      <offline-notice-banner></offline-notice-banner>

      <div @click="$sidebar.displaySidebar(false)">
        <nuxt></nuxt>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "~/components/layouts/argon/DashboardNavbar.vue";
import ContentFooter from "~/components/layouts/argon/ContentFooter.vue";
import DashboardContent from "~/components/layouts/argon/Content.vue";
import ShopSelectDropdown from "@/components/layouts/ShopSelectDropdown";
import ConfirmLogoutModal from "@/components/auth/ConfirmLogoutModal.vue";
import ProgressTrackerBanner from "@/components/widgets/ProgressTrackerBanner.vue";
import OfflineNoticeBanner from "@/components/widgets/OfflineNoticeBanner.vue";
import MissingVatNotice from "@/components/pages/shop/MissingVatNotice.vue";
import UpgradeNotice from "@/components/widgets/UpgradeNotice.vue";
import ShopQuickStartModal from "@/components/widgets/ShopQuickStartModal";
import UpgradeSubscriptionModal from "@/components/widgets/UpgradeSubscriptionModal";
import { SlideYUpTransition } from "vue2-transitions";
import { mapGetters } from "vuex";

export default {
  name: "shop-layout",
  middleware: ["auth", "check-shop-relation", "check-page-status"],
  components: {
    DashboardNavbar,
    ContentFooter,
    DashboardContent,
    ShopSelectDropdown,
    ConfirmLogoutModal,
    ProgressTrackerBanner,
    OfflineNoticeBanner,
    MissingVatNotice,
    SlideYUpTransition,
    UpgradeNotice,
    ShopQuickStartModal,
    UpgradeSubscriptionModal,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  data() {
    return {
      dashboardNavbar: {
        dropdownMenuItems: [
          {
            path: "/shop/user/profile",
            icon: "ni ni-single-02",
            key: "profile",
          },
          {
            path: "/shop/profile",
            icon: "ni ni-box-2",
            key: "shop",
          },
          {
            path: "/shop/integrations",
            icon: "ni ni-spaceship",
            key: "integrations",
          },
          {
            path: "/shop/subscription",
            icon: "ni ni-archive-2",
            key: "subscription",
          },
        ],
      },
      showConfirmLogoutModal: false,
    };
  },
  methods: {
    handleShowQuickStartGuide() {
      this.$store.commit("shop/SET_SHOW_QUICK_START_MODAL", true);
    },
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("scrollbar-inner");
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
