const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['check-active-subscription'] = require('../middleware/check-active-subscription.js')
middleware['check-active-subscription'] = middleware['check-active-subscription'].default || middleware['check-active-subscription']

middleware['check-auth'] = require('../middleware/check-auth.js')
middleware['check-auth'] = middleware['check-auth'].default || middleware['check-auth']

middleware['check-page-status'] = require('../middleware/check-page-status.js')
middleware['check-page-status'] = middleware['check-page-status'].default || middleware['check-page-status']

middleware['check-shop-relation'] = require('../middleware/check-shop-relation.js')
middleware['check-shop-relation'] = middleware['check-shop-relation'].default || middleware['check-shop-relation']

middleware['check-supplier-relation'] = require('../middleware/check-supplier-relation.js')
middleware['check-supplier-relation'] = middleware['check-supplier-relation'].default || middleware['check-supplier-relation']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

export default middleware
