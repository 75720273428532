<template>
  <modal :show.sync="show" size="lg" headerClasses="border-bottom">
    <!-- Header Title -->
    <template #header>
      <h3 class="mb-0">{{ $t("quick-start-guide") }}</h3>
    </template>

    <!-- Is Loading -->
    <div v-if="isLoading" class="text-center py-4">
      <i class="fas fa-spinner fa-spin mr-1 text-xl"></i>
    </div>

    <!-- Content -->
    <div v-else>
      <p class="mb-4">
        {{ $t("quick-start-guide-greeting") }}
      </p>

      <quick-start-steps :quickStartSteps="quickStartSteps"></quick-start-steps>

      <p class="text-muted text-sm mt-4">
        {{ $t("quick-start-guide-notice") }}
      </p>
    </div>
  </modal>
</template>

<script>
import QuickStartSteps from "@/components/widgets/QuickStartSteps";
export default {
  name: "ShopQuickStartModal",
  components: {
    QuickStartSteps,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    show: {
      get() {
        this.onload();
        return this.$store.state.shop.showQuickStartGuideModal;
      },
      set(value) {
        this.$store.commit("shop/SET_SHOW_QUICK_START_MODAL", value);
      },
    },
    quickStartSteps() {
      return [
        {
          title: this.$t("quick-start-guide-steps.step-1-title"),
          text: this.$t("quick-start-guide-steps.step-1-text"),
          link: "/shop/integrations",
          onClick: () => (this.show = false),
          completed: this.$store.state.integrations.all.data.length > 0,
        },
        {
          title: this.$t("quick-start-guide-steps.step-2-title"),
          text: this.$t("quick-start-guide-steps.step-2-text"),
          link: "/shop/products/search-products",
          onClick: () => (this.show = false),
          completed:
            this.$store.state.myProducts.products.data.length > 0 ||
            this.$store.state.importProduct.products.data.length > 0,
        },
        {
          title: this.$t("quick-start-guide-steps.step-3-title"),
          text: this.$t("quick-start-guide-steps.step-3-text"),
          link: "/shop/products/import-list",
          onClick: () => (this.show = false),
          completed: this.$store.state.myProducts.products.data.length > 0,
        },
        {
          title: this.$t("quick-start-guide-steps.step-4-title"),
          text: this.$t("quick-start-guide-steps.step-4-text"),
          onClick: () => (this.show = false),
          completed: false,
        },
      ];
    },
  },
  methods: {
    onload() {
      this.isLoading = true;
      Promise.all([
        this.$store.dispatch("integrations/fetchAll"),
        this.$store.dispatch("importProduct/fetchImportProducts"),
        this.$store.dispatch("myProducts/fetchMyProducts"),
      ]).then(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>

<style></style>
