// state
export const state = () => ({
  locale: ""
});

// getters
export const getters = {
  locale: state => state.locale
};

// mutations
export const mutations = {
  SET_LOCALE(state, payload) {
    state.locale = payload;
  }
};

// actions
export const actions = {
  setLocale({ commit }, payload) {
    commit("SET_LOCALE", payload);
  }
};
