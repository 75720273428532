<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar logoLink="/supplier/dashboard">
      <template slot="links">
        <sidebar-item
          :link="{
            name: $t('partnerships'),
            icon: 'ni ni-tie-bow text-success',
            path: '/supplier/partnerships',
          }"
        >
        </sidebar-item>
      </template>

      <template slot="links-after">
        <hr class="my-3" />
        <h6 class="navbar-heading p-0 text-muted">
          {{ $t("getting-started") }}
        </h6>

        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://vendino.com/kontakt"
              target="_blank"
              rel="noopener"
            >
              <i class="ni ni-support-16"></i>
              <span class="nav-link-text">{{ $t("help-center") }}</span>
            </a>
          </li>
        </ul>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar
        color="info"
        :dropdownMenuItems="dashboardNavbar.dropdownMenuItems"
      >
        <supplier-select-dropdown></supplier-select-dropdown>
        <template slot="dropdownMenu">
          <div class="dropdown-divider"></div>
          <button
            type="button"
            @click="showConfirmLogoutModal = true"
            class="dropdown-item"
          >
            <i class="ni ni-user-run"></i>
            <span>{{ $t("logout") }}</span>
          </button>
        </template>
      </dashboard-navbar>

      <!-- Confirm logout modal -->
      <confirm-logout-modal
        :show.sync="showConfirmLogoutModal"
        redirectPath="/supplier/auth/login"
      ></confirm-logout-modal>

      <div @click="$sidebar.displaySidebar(false)">
        <nuxt></nuxt>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "~/components/layouts/argon/DashboardNavbar.vue";
import ContentFooter from "~/components/layouts/argon/ContentFooter.vue";
import DashboardContent from "~/components/layouts/argon/Content.vue";
import SupplierSelectDropdown from "@/components/layouts/SupplierSelectDropdown.vue";
import ConfirmLogoutModal from "@/components/auth/ConfirmLogoutModal.vue";

export default {
  name: "supplier-layout",
  middleware: ["auth", "check-supplier-relation"],
  components: {
    DashboardNavbar,
    ContentFooter,
    DashboardContent,
    SupplierSelectDropdown,
    ConfirmLogoutModal,
  },
  data() {
    return {
      dashboardNavbar: {
        dropdownMenuItems: [
          {
            path: "/supplier/user/profile",
            icon: "ni ni-single-02",
            key: "profile",
          },
        ],
      },
      showConfirmLogoutModal: false,
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("scrollbar-inner");
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
