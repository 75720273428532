<template>
  <div class="quick-start-steps">
    <div
      class="d-flex mb-2"
      v-for="(step, index) in quickStartSteps"
      :key="index"
    >
      <!-- Completed step! -->
      <div class="d-flex completed-step" v-if="step.completed">
        <div
          class="icon bg-white border icon-shape text-success rounded-circle shadow d-block"
        >
          <i class="fas fa-check"></i>
        </div>
        <div class="pl-3">
          <p class="text-md font-weight-bold text-light mt-0 mb-0">
            {{ step.title }}
          </p>
          <p class="text-sm text-light">
            {{ step.text }}
          </p>
        </div>
      </div>
      <!-- Incomplete step -->
      <div
        class="d-flex incomplete-step"
        v-else
        @click="handleOnClick(step)"
        style="cursor:pointer;"
      >
        <div
          class="icon bg-success icon-shape font-weight-medium text-white rounded-circle shadow d-block"
        >
          {{ index + 1 }}
        </div>
        <div class="pl-3">
          <p class="text-md font-weight-bold text-default mt-0 mb-0 step-title">
            {{ step.title }}
          </p>
          <p class="text-sm">
            {{ step.text }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuickStartSteps",
  props: {
    quickStartSteps: {
      type: Array
    }
  },
  methods: {
    handleOnClick(step) {
      if (step.link) {
        this.$router.push(step.link);
      }

      if (step.onClick) {
        step.onClick();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.quick-start-steps {
  .completed-step {
    .fa-check {
      animation: scaleInOut 0.5s ease-in;
    }
  }

  @keyframes scaleInOut {
    0% {
      transform: scale(10%);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    50% {
      transform: scale(115%);
    }
    100% {
      transform: scale(100%);
    }
  }

  .incomplete-step {
    .step-title {
      &:after {
        content: "\EA11";
        position: relative;
        font-family: "NucleoIcons";
        font-size: 14px;
        left: 5px;
        top: 3px;
        line-height: 1;
        opacity: 0;
        transition: all 0.1s ease-out;
      }
    }

    &:hover {
      .step-title:after {
        left: 0;
        opacity: 1;
      }
    }
  }
}
</style>
