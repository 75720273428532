<template>
  <div
    class="d-flex justify-content-center align-items-center transfer-landing-layout"
    style="min-height: 100vh"
  >
    <div class="mt--6 text-center content">
      <nuxt></nuxt>

      <div class="spinner white mt-5 mx-auto"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransferLandingLayout",
};
</script>

<style lang="scss" scoped>
.transfer-landing-layout {
  min-height: 100vh;
  background: url("/img/auth_bg.svg") no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: #2dce89;
  padding-top: 1em;

  .content {
    color: white;
  }
}
@media (min-width: 1200px) {
  .transfer-landing-layout {
    padding-top: 5em;
  }
}
</style>
