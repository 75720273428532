import axios from "axios";

// state
export const state = () => ({
  paymentMethods: [],
  invoices: [],
  subscriptions: [],
  partnerships: [],
  partnershipRequests: [],
  productCategories: [],
  users: [],
  showQuickStartGuideModal: false,
  showUpgradeSubscriptionModal: false,
});

// getters
export const getters = {
  paymentMethods: (state) => state.paymentMethods,
  invoices: (state) => state.invoices,
  subscriptions: (state) => state.subscriptions,
  activeSubscription: (state) =>
    state.subscriptions.find((el) => {
      return el.status === "active";
    }),
  oldSubscriptions: (state) =>
    state.subscriptions.filter((el) => {
      return el.status != "active";
    }),
  partnerships: (state) => state.partnerships,
  partnershipRequests: (state) => state.partnershipRequests,
  productCategories: (state) => state.productCategories,
  users: (state) => state.users,
  showQuickStartGuideModal: (state) => state.showQuickStartGuideModal,
  showUpgradeSubscriptionModal: (state) => state.showUpgradeSubscriptionModal,
};

// mutations
export const mutations = {
  SET_PAYMENT_METHODS(state, payload) {
    state.paymentMethods = payload;
  },
  SET_INVOICES(state, payload) {
    state.invoices = payload;
  },
  SET_SUBSCRIPTIONS(state, payload) {
    state.subscriptions = payload;
  },
  SET_PARTNERSHIPS(state, payload) {
    state.partnerships = payload;
  },
  SET_PARTNERSHIP_REQUESTS(state, payload) {
    state.partnershipRequests = payload;
  },
  SET_PRODUCT_CATEGORIES(state, payload) {
    state.productCategories = payload;
  },
  SET_USERS(state, payload) {
    state.users = payload;
  },
  SET_SHOW_QUICK_START_MODAL(state, payload) {
    state.showQuickStartGuideModal = payload;
  },
  SET_SHOW_UPGRADE_SUBSCRIPTION_MODAL(state, payload) {
    state.showUpgradeSubscriptionModal = payload;
  },
};

// actions
export const actions = {
  fetchPaymentMethods({ commit }) {
    return axios.get("/shop/payment-methods").then(({ data }) => {
      commit("SET_PAYMENT_METHODS", data.data);
    });
  },
  fetchInvoices({ commit }, options) {
    const params = { ...options };

    return axios.get("/shop/invoices", { params }).then(({ data }) => {
      commit("SET_INVOICES", data);
    });
  },
  async deletePaymentMethod({ commit }, method_id) {
    try {
      const {
        data: { data },
      } = await axios.delete(`/shop/payment-methods/${method_id}`);

      commit("SET_PAYMENT_METHODS", data);
    } catch (e) {
      commit("SET_PAYMENT_METHODS", null);
    }
  },
  fetchSubscriptions({ commit }) {
    return axios.get("/shop/subscriptions").then(({ data }) => {
      commit("SET_SUBSCRIPTIONS", data.data);
    });
  },
  fetchPartnerships({ commit }) {
    return axios.get("/shop/partnerships").then(({ data }) => {
      commit("SET_PARTNERSHIPS", data.data);
    });
  },
  fetchPartnershipRequests({ commit }) {
    return axios.get("/shop/partnership-requests").then(({ data }) => {
      commit("SET_PARTNERSHIP_REQUESTS", data.data);
    });
  },
  fetchProductCategories({ commit }) {
    return axios.get("/shop/product-categories").then(({ data }) => {
      commit("SET_PRODUCT_CATEGORIES", data);
    });
  },
  fetchUsers({ commit }) {
    return axios.get("/shop/users").then(({ data }) => {
      commit("SET_USERS", data);
    });
  },
  createUser({}, params) {
    return axios.post("/shop/users", params);
  },
  deleteUser({}, email) {
    return axios.delete(`/shop/users/${email}`);
  },
  patchUser({}, { email, role }) {
    return axios.patch(`/shop/users/${email}`, { role });
  },
};
