var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_vm._v(" "),_c('side-bar',{attrs:{"logoLink":"/shop/dashboard"},scopedSlots:_vm._u([{key:"links-after",fn:function(){return [_c('hr',{staticClass:"my-3"}),_vm._v(" "),_c('h6',{staticClass:"navbar-heading p-0 text-muted"},[_vm._v("\n        "+_vm._s(_vm.$t("getting-started"))+"\n      ")]),_vm._v(" "),_c('ul',{staticClass:"navbar-nav mb-md-3"},[_c('li',{staticClass:"nav-item"},[_c('nuxt-link',{staticClass:"nav-link",attrs:{"to":"/shop/integrations"}},[_c('i',{staticClass:"ni ni-spaceship"}),_vm._v(" "),_c('span',{staticClass:"nav-link-text"},[_vm._v(_vm._s(_vm.$t("integrations")))])])],1),_vm._v(" "),_c('li',{staticClass:"nav-item",on:{"click":function($event){return _vm.handleShowQuickStartGuide()}}},[_c('div',{staticClass:"nav-link",staticStyle:{"cursor":"pointer"}},[_c('i',{staticClass:"ni ni-support-16"}),_vm._v(" "),_c('span',{staticClass:"nav-link-text"},[_vm._v(_vm._s(_vm.$t("quick-start-guide")))])])])])]},proxy:true},{key:"bottom",fn:function(){return [(_vm.$sidebar.isMinimized === false || _vm.$sidebar.hovered === true)?_c('upgrade-notice'):_vm._e()]},proxy:true}])},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('search-products'),
          icon: 'fas fa-tasks text-info',
          path: '/shop/products/search-products',
        }}}),_vm._v(" "),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('manage-products'),
          icon: 'ni ni-bag-17 text-orange',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('my-import-list'),
            path: '/shop/products/import-list',
          }}}),_vm._v(" "),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('my-products'),
            path: '/shop/products/my-products',
          }}}),_vm._v(" "),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('my-feeds'),
            path: '/shop/products/feeds',
          }}})],1)],1)],2),_vm._v(" "),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"color":"success","dropdownMenuItems":_vm.dashboardNavbar.dropdownMenuItems},scopedSlots:_vm._u([{key:"notices",fn:function(){return [_c('slide-y-up-transition',{attrs:{"group":""}},[(!_vm.user.shop.vat)?_c('missing-vat-notice',{key:0,staticClass:"ml-2"}):_vm._e()],1)]},proxy:true}])},[_vm._v(" "),_c('shop-select-dropdown'),_vm._v(" "),_c('template',{slot:"dropdownMenu"},[_c('div',{staticClass:"dropdown-divider"}),_vm._v(" "),_c('button',{staticClass:"dropdown-item",attrs:{"type":"button"},on:{"click":function($event){_vm.showConfirmLogoutModal = true}}},[_c('i',{staticClass:"ni ni-user-run"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("logout")))])])])],2),_vm._v(" "),_c('confirm-logout-modal',{attrs:{"show":_vm.showConfirmLogoutModal},on:{"update:show":function($event){_vm.showConfirmLogoutModal=$event}}}),_vm._v(" "),_c('shop-quick-start-modal'),_vm._v(" "),_c('upgrade-subscription-modal'),_vm._v(" "),_c('progress-tracker-banner'),_vm._v(" "),_c('offline-notice-banner'),_vm._v(" "),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('nuxt')],1),_vm._v(" "),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }